import React from 'react'
import { graphql } from 'gatsby'
import { makeStyles, Container, Grid } from '@material-ui/core'
import Pager from '../components/pagerProducts'
import Master from '../components/Master';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Products from '../components/ListView/Products/Products';

const ProductsPaginate = ({ data, pageContext }) => {
    const products = data.allProduct.nodes
    const page = data.allPage.nodes[0]

    const useStyles = makeStyles(theme => ({
        center: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
        }
    }));

    const classes = useStyles();

    return (
        <Master title={page.title}>
            <GatsbySeo
                title={page.title}
                description={page.description}
                canonical={`${process.env.SITE_URL}/produtos`}
                noindex={false}
                nofollow={false}
                openGraph={{
                    url: `${process.env.SITE_URL}/produtos`,
                    title: page.title,
                    description: page.description,
                    images: [
                        {
                            url: `${process.env.SITE_IMAGE_OG_512}`,
                            width: 512,
                            height: 512,
                            alt: page.title
                        }
                    ],
                    site_name: `${process.env.SITE_NAME}`,
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <Container maxWidth="md" component="main" className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1 className={classes.center}>{page.title}</h1>
                    </Grid>
                    <Grid item xs={12} >
                        <Pager pageContext={pageContext} />
                        <Products products={products} />
                        <Pager pageContext={pageContext} />
                    </Grid>
                </Grid>
            </Container>
        </Master>
    )
}

export const query = graphql`
    query($skip: Int!, $limit:Int!){
        allProduct(
            sort: {fields:updatedAt, order: DESC}
            skip: $skip
            limit: $limit
        ) {
            nodes {
                id
                title
                slug
                price
                description
                image
                imageThumbnail
            }
        }
        allPage(filter: {id: {eq: "5efa29d0226aed4281876a69"}}) {
            nodes {
                title
                description
                keywords
            }
        }
    }
`;

export default ProductsPaginate